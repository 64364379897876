<template>
    <div class="custom-content">
        <div class="custom-wrap" v-if="customList.length >0">
            <div class="top-btn">
                <div class="create-btn" @click="createLive">创建直播间</div>
            </div>
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="直播间信息" align="left">
                    <template slot-scope="scope">
                        <div class="item-content">
                            <div class="item-left">
                                <img :src="scope.row.cover" alt="">
                            </div>
                            <div class="item-right">
                                <div class="item-title">{{scope.row.title}}</div>
                                <div class="right-content">
                                    <div class="content-item">
                                        <span class="content-left">开播</span>
                                        <span class="content-right">{{scope.row.live_broadcast_begin_time}}</span>
                                    </div>
                                    <div class="content-item">
                                        <span class="content-left">结束</span>
                                        <span class="content-right">{{scope.row.live_broadcast_end_time}}</span>
                                    </div>
                                    <div class="content-item">
                                        <span class="content-left">主播</span>
                                        <span class="content-right">{{scope.row.anchor_name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="直播状态" align="center"></el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="400">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="primary" :underline="false" @click="goToControl(scope.row)">
                                控制台
                            </el-link>
                            <el-link type="primary" :underline="false" @click="toGoodsManage(scope.row)">
                                运营
                            </el-link>
                            <el-link type="success" :underline="false" @click="editLive(scope.row)">
                                编辑
                            </el-link>
                            <el-link type="danger" :underline="false" @click="delLive(scope.row)">
                                删除
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
        </div>
        <div class="custom-wrap" v-else>
            <el-table class="customTable noData" :data="customList"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <template slot="empty">
                    <div class="no-data">
                        <div class="no-data-content">
                            <img src="../../../../../assets/images/student/liveManage.png">
                            <span class="no-data-title">暂时没有直播，快去创建吧</span>
                            <div class="btn-box">
                                <div class="create-btn" @click="createLive">创建直播间</div>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table-column prop="content" label="直播间信息" align="left">
                </el-table-column>
                <el-table-column prop="status" label="直播状态" align="center">
                </el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="400">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {stuOpLiveList, stuOpLiveDel} from '@/utils/apis'

    export default {
        name: "livePromotionIndex",
        components: {
            pagination
        },
        data() {
            return {
                customList: [],
                total: 0,
                currentPageNum: 1,
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
                type:''
            }

        },
        created() {
            this.getLiveList();
        },
        methods: {
            getLiveList() {
                this.customList = [];
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId
                }
                stuOpLiveList(param).then(res => {
                    this.type = res.data
                    if (res.data !== null) {
                        this.customList.push(res.data)
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getStudentList();
            },
            createLive() {
                if(this.type != null){
                      this.$confirm('一场考试只能创建一场直播', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                        type: 'warning'
                    }).then(() => {
                    }).catch(() => {
                        this.$message.error('已取消');
                    })
                    return
                }
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/createLive',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examCenter/createLive',
                    })
                }
            },
            editLive(row) {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/createLive',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            edit_id: row.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examCenter/createLive',
                        query: {
                            edit_id: row.id
                        }
                    })
                }
            },
            delLive(row) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    stuOpLiveDel({id: row.id}).then((res) => {
                        this.getLiveList()
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            goToControl(row) {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/liveControl',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examCenter/liveControl',
                    })
                }
            },
            toGoodsManage(row) {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/goodsManageIndex',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examCenter/goodsManage',
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .create-btn {
            width: 118px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            color: #2DC079;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
        }

        .custom-wrap {
            margin: 0 20px;
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            .customTable {
                margin-top: 12px;

                &.noData {
                    ::v-deep .el-table__body-wrapper {
                        width: 100%;
                        height: 100% !important;

                        .el-table__empty-block {
                            border: unset;
                            display: flex;
                            flex-direction: column;

                            .el-table__empty-text {
                                flex: 1;
                                height: 1%;
                            }
                        }
                    }
                }

                .no-data {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .no-data-content {
                        display: flex;
                        flex-direction: column;

                        .no-data-title {
                            color: #999999;
                            margin-top: 30px;
                        }

                        .btn-box {
                            display: flex;
                            justify-content: center;
                        }

                        .create-btn {
                            margin-top: 40px;
                        }
                    }
                }
            }

            .item-content {
                display: flex;
                align-items: center;

                .item-left {
                    width: 192px;
                    height: 108px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-right {
                    width: 192px;
                    height: 108px;
                    margin-left: 20px;

                    .item-title {
                        color: #333333;
                    }

                    .right-content {
                        margin-top: 14px;

                        .content-item {
                            display: flex;
                            font-size: 14px;
                            color: #666666;

                            .content-right {
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }

            .pages-center {
                margin-bottom: 0;
            }
        }
    }
</style>